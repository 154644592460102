$(document).on('ready turbolinks:load', function () {
  $('.multiselect').multiselect({numberDisplayed: 0});

  tinyMCE.remove();
  tinyMCE.init({
    selector: "textarea.tinymce",
    theme_advanced_toolbar_location: "top",
    theme_advanced_toolbar_align: "left",
    theme_advanced_statusbar_location: "bottom"
  });
});
