printContainer = (print, name) => {
  const printContent = document.getElementById(print).innerHTML;
  let win = window.open();

  self.focus();
  win.document.open();
  win.document.write('<' + 'html' + '><' + 'head' + '><' + 'style' + '>');
  win.document.write('body, td { font-family: Verdana; font-size: 12pt;} .tip-wrap {float: left; margin-left: 20px;} .clear {clear: both;}');
  win.document.write('<' + '/' + 'style' + '><' + '/' + 'head' + '><' + 'body' + '>');
  win.document.write(name);
  win.document.write('<' + 'br' + '/' + '>' + '<' + 'br' + '/' + '>');
  win.document.write(printContent);
  win.document.write('<' + '/' + 'body' + '><' + '/' + 'html' + '>');
  win.document.close();
  win.print();
  win.close();
};
