require.context('../images', true);

require('@rails/ujs').start();
require('turbolinks').start();

import '../src/javascripts/bootstrap';
import '../src/javascripts/bootstrap-multiselect';
import '../src/javascripts/initializers';
import '../src/javascripts/print';
import 'bootstrap-sass/assets/javascripts/bootstrap';
import '../src/javascripts/jquery_nested_form';
